<template>
    <div class="navBar">
        <ul>
            <li v-for="item in navBarList" :key="item.name" @click="switchCurrent(item.id)">
                <div>
                    <img :src="item.id === id? item.currentImg : item.img " alt="">
                    <span>{{item.name}}</span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  name:"navBar",
    props:['id'],
    data () {
        return {
            navBarList:[
                {
                    name: '首页',
                    img: require('@/assets/img/home.png'),
                    currentImg: require('@/assets/img/home-select.png'),
                    url: this.$depth == 4 ? '/street-home' : '/home',
                    id: 0
                },
                {
                    name: '工作台',
                    img: require('@/assets/img/navBar1@2x.png'),
                    currentImg: require('@/assets/img/navBar1-current@2x.png'),
                    url: '/work',
                    id: 1
                },
                {
                    name: '统计',
                    img: require('@/assets/img/statistics.png'),
                    currentImg: require('@/assets/img/statistics-select.png'),
                    url: '/statistics',
                    id: 2
                },
                {
                    name: '我的',
                    img: require('@/assets/img/navBar2@2x.png'),
                    currentImg: require('@/assets/img/navBar2-current@2x.png'),
                    url:  '/my',
                    id: 3
                }
                ],
        }
    },
    methods: {
        switchCurrent (id) {
            this.navBarList.forEach(item => {
                if (item.id === id) {
                  console.log(this.$depth)
                    this.$router.push(item.url)
                }
            })
        }
    },
    computed: {
    },
}
</script>

<style lang="scss" scoped>
    .navBar {
        z-index: 999;
        width: 100%;
        height: 166px;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: #fff;
        ul {
            width: 100%;
            height: 100%;
            display: flex;
            li {
                flex: 1;
                text-align: center;
                padding-top: 20px;
                img {
                    height: 37px;
                    margin-bottom: 15px;
                }
                span {
                    display: block;
                    font-size: 24px;
                    color: #666;
                }
            }
        }
    }
</style>
