<template>
  <div class="page">
    <nav-bar :id="2"></nav-bar>
    <div class="banner">
      <img style="width: 100%" :src="bannerImg" />
    </div>
    <div class="basic">
      <div v-for="item, index in basicList" :key="index" @click="clickFn(item.url)" class="basic-item">
        <img style="width: 34px" :src="item.img" />
        <div>{{ item.name }}</div>
      </div>
    </div>
    <div class="work">
      <div class="work-title">事件统计</div>
      <div style="display: flex; justify-content: space-between; flex-wrap: wrap">
        <div v-for="item, index in workList" :key="index" @click="clickFn(item.url)" class="work-item">
          <img style="width: 40px" :src="item.img" />
          <div style="margin-top: 8px">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar/navBar";
import { getImageStream } from "@/utils";

export default {
  name: "statistics",
  components: {
    navBar
  },
  data() {
    return {
      bannerImg: getImageStream('files/wx/images/content/statistics-banner.png',process.env.VUE_APP_BASE_BACK_URL),
      workList: [
        {
          url: '/statistics-patrol',
          name: '巡查统计',
          eName: 'Patrol',
          color: '#3D7EFE',
          img: getImageStream('files/wx/images/content/statistics_patrol.png',process.env.VUE_APP_BASE_BACK_URL),
        },
        {
          url: '/statistics-visit',
          name: '走访统计',
          eName: 'Visit',
          color: '#0AB9B3',
          img: getImageStream('files/wx/images/content/statistics_visit.png',process.env.VUE_APP_BASE_BACK_URL),
        },
        {
          url: '/statistics-active',
          name: '活动统计',
          eName: 'Activity',
          color: '#ED4747',
          img: getImageStream('files/wx/images/content/statistics_activity.png',process.env.VUE_APP_BASE_BACK_URL),
        },
        {
          url: '/statistics-check',
          name: '检查统计',
          eName: 'Check',
          color: '#FFA93A',
          img: getImageStream('files/wx/images/content/statistics_check.png',process.env.VUE_APP_BASE_BACK_URL),
        },
        {
          url: '/statistics-maintain',
          name: '维护更新统计',
          eName: 'Maintain',
          color: '#ED4747',
          img: getImageStream('files/wx/images/content/statistics_maintain.png',process.env.VUE_APP_BASE_BACK_URL),
        },
        {
          url: '/statistics-problem',
          name: '解决问题统计',
          eName: 'Problem',
          color: '#3D7EFE',
          img: getImageStream('files/wx/images/content/statistics_resolve.png',process.env.VUE_APP_BASE_BACK_URL),
        }
      ],
      basicList: [
        {
          url: '/statistics-user',
          name: '居民统计',
          eName: 'Residents',
          color: '#3D7EFE',
          img: getImageStream('files/wx/images/content/statistics_res.png',process.env.VUE_APP_BASE_BACK_URL),
        },
        {
          url: '/statistics-house',
          name: '房屋统计',
          eName: 'House',
          color: '#FFA93A',
          img: getImageStream('files/wx/images/content/statistics_house.png',process.env.VUE_APP_BASE_BACK_URL),
        },
        {
          url: '/statistics-place',
          name: '场所统计',
          eName: 'Place',
          color: '#0AB9B3',
          img: getImageStream('files/wx/images/content/statistics_place.png',process.env.VUE_APP_BASE_BACK_URL),
        },
        {
          url: '/statistics-device',
          name: '设备统计',
          eName: 'Equipment',
          color: '#ED4747',
          img: getImageStream('files/wx/images/content/statistics_device.png',process.env.VUE_APP_BASE_BACK_URL),
        }
      ],
    }
  },
  methods: {
    clickFn(url) {
      this.$router.push(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  background-color: #F5F5F5;
  min-height: 100vh;
  padding-bottom: 186px;
  margin: 24px 30px;
}

.banner {
  position: relative;
}

.banner::after {
  content: ' ';
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -10px;
  background: rgba(88, 132, 255, 0.31);
  filter: blur(14px);
  width: 606px;
  height: 40px;
}

.basic {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 154px;
  background-color: #fff;
  margin-top: 44px;
  border-radius: 20px;
  box-shadow: 0px 2px 12px 0px rgba(102, 102, 102, 0.1);
}

.basic-item {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  color: #333;
  text-align: center;
}

.work {
  margin-top: 24px;
}

.work-title {
  line-height: 88px;
  font-size: 32px;
  font-weight: 600;
  color: #333;
}

.work-item {
  width: 330px;
  height: 176px;
  border-radius: 20px;
  background-color: #fff;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  box-shadow: 0px 2px 12px 0px rgba(102, 102, 102, 0.1);
}
</style>
